import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MobileNavbar from '../Screenwriters/MobileNavbar';
import SwaNavbar from '../Screenwriters/SwaNavbar';
import SwaFooter from '../Screenwriters/SwaFooter';
import './ArchiveData.css';

function ArchiveData() {
    const [isMobile, setIsMobile] = useState(() => {
        const initialValue = localStorage.getItem('isMobile');
        return initialValue ? JSON.parse(initialValue) : false;
    });

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await axios.get('https://swa.nexzendigital.com/api/archive/list');
                console.log('Response from API:', response.data);

                setArticles(response.data.data.article_list);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    return (
        <>
            {isMobile ? <MobileNavbar /> : <SwaNavbar />}
            <div className="row">
                <div className="col-md-12">
                    <h1 className="title_download bold line text-center">Archive-Articles</h1>
                </div>
            </div>
            <div className="table-responsive"> {/* Add this wrapper for responsiveness */}
                <table className="articles-table table border-container">
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
    {articles.map((article, index) => (
        <tr key={index}>
            <td>
                <a href={`/archivepage/${article.id}`} target="_blank" rel="noopener noreferrer">
                    {article.art_main_heading}
                </a>
            </td>
            <td>{article.author_name}</td>
            <td>{article.art_date}</td>
        </tr>
    ))}
</tbody>

                </table>
            </div>
            <SwaFooter />
        </>
    );
}

export default ArchiveData;
