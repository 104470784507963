import React ,{ useState, useEffect ,useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle,faCalendarAlt, faCogs, faUserSecret,faPencilAlt,faBan,faExclamationTriangle,faComments } from '@fortawesome/free-solid-svg-icons';
import './Terms.css'; // Make sure to create a corresponding CSS file
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar"; // Importing CSS for styling

const Refund = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 768);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 768));
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
        {isMobile ? <MobileNavbar /> : <SwaNavbar />}

    <h1 className='headings'> Refund and Cancellation Policy</h1>

    <div className="terms-container border-container">

      <section className="term-section">
       
        <h2> <FontAwesomeIcon icon={faInfoCircle} /> GENREAL</h2>
        <ul>
          <li className='li-p'>


          SWA provides online payment facilities through its websitewww.swaindia.org for SWA membership, Readmission, Renewal, Upgradation, Penalty, Script Registration, purchase/registration of event/workshop/conference tickets, donation, sponsorship amount, courier charges, printing charges (for SWA membership card), DSC commission, as well as any other charges or services as determined, fixed and revised by SWA Constitution and Byelaws and/or its working Executive Committee from time to time.
        </li>
        <li className='li-p'>



        Our Constitution Book and Byelaws are available as PDF files, free to download on our website the link of which is also shared with every new member in the welcome email. Members can also order for the hard copy of the Constitution Book. SWA charges additional courier charges if the Constitution Book and/or SWA Membership Card needs to be couriered to the member. 
        </li>
        <li className='li-p'>



        SWA has strict NO REFUNDS policy for any successful transaction related to any of its services or functions.
        </li>
        <li>



        We initiate a refund only in the case of a technical error in which money has been deducted and reflects in our Payment Gateway account but the transaction did not fetch the desired result on the system due to a technical issue. For all such cases, the concerned member must bring the issue to SWA's notice within twenty-one (21) days, in case of online script registration within five (5) days, of the said transaction by sending an email with specific details and screenshots. After twenty-one (21) days, in case of online script registration after five (5) days, such refund requests shall not be entertained.(Send us an email at contact@swaindia.org with screenshots of the error page and/or the last page that you saw while the transaction and message/email of money being deducted. We will check and initiate a refund if the money reflects in our Payment Gateway account, within 8-10 business day.)
</li>
<li>





In case, the money is deducted and it does not reflect in our Payment Gateway account then it's a situation which is between the payee's bank/card and the payment gateway, and NOT under SWA's functioning.
</li>
<li>





Cancellation or Expiry of SWA membership takes place at its own when the membership expires OR if the Executive Committee resolves to cancel the membership of any member for disciplinary or other reasons. 
</li>
<li>







We accept payments through our Online Payment Gateway partner/s Our Online Payment Gateway partner has multiple options to make a payment like credit card, debit card, net banking, wallets, UPI payments etc. 
</li>
<li>

SWA’s fees/charges remain the same online and offline as determined, fixed and revised by SWA Constitution and Byelaws and/or its working Executive Committee from time to time. </li>
<li>
All the payments are being done at the Third Party Payment Gateway partners. The SWA website is not aware of any specific details of the Credit/Debit Cards, mode of payment used SWA bears the expenses of the Convenience Fees to its Payment Gateway partners, for all the transactions.  </li>

<li>


SWA website may or may not secure the data which the user fills while making a transaction, namely - Name, Email ID, Phone number, Billing Address and any additional special remarks entered by the user along with the records of the transactions like Payment Gateway reference number, order ID, transaction ID, date and time of transaction etc.
 </li>

        </ul>
      </section>

      <section className="term-section">
       
        <h2> <FontAwesomeIcon icon={faCalendarAlt} /> SWA EVENTS</h2>
        <ul>
          <li className='li-p'>




          All the details about SWA events (conferences, workshops, summits, etc.) shall be uploaded on the website of SWA along with other social networking platforms. There shall be details of all necessary information about the events like important dates, venue, price of tickets and all other terms and conditions of the online/offline booking. Stay tuned. 
        </li>
        <li className='li-p'>





        Arbitrary Cancellation and Refund is not allowed, after bookings. However, in case of an emergency situation, the Hon. General Secretary and/or the Executive Committee may use their discretion and entertain a refund request. 
        </li>
        <li className='li-p'>



        We shall inform the process of refund through email incase a refund request is approved by the Hon. General Secretary and/or the Executive Committee for any special circumstances, or if SWA cancels the event due to any emergency circumstances. Usually it takes 8-10 business day to initiate such a refund. You may write an email to event@swaindia.orgto request for a refund on an event ticket under special circumstances.        </li>
       
<li className='li-p'>



The option of buying event tickets at SWA office, with cash or card swipe, will depend on the discretion of the Hon. General Secretary and/or the Executive Committee for any specific event. This policy may vary from event to event. In case the purchase of event tickets at the SWA office with cash or card swipe has been allowed then the same shall be offered only if there are unreserved seats/spot left. SWA does not keep a specific quota for purchase at SWA office, and if all the seats/spots get sold out online, there will not be any purchases at SWA office.
</li>
<li className='li-p'>



Similarly, same day On-The-Spot purchase of event tickets shall be allowed as per the discretion of the Hon. General Secretary and/or the Executive Committee for any specific event and this policy may vary from event to event. In case same day On-The-Spot purchase of event tickets has been allowed then the same shall be offered only if there are unreserved seats/spot left. SWA does not keep a specific quota for On-The-Spot purchases, and if all the seats/spots get sold out online, there will not be any On-The-Spot purchases.
</li>
<li className='li-p'>



For all event tickets, there will be an additional 18% GST per transaction over and above the workshop/conference/event fees, which shall not be applicable in case SWA is doing a fundraiser activity and asking for donations.
</li>

        </ul>
      </section>
    

      {/* Add more sections as needed */}
    </div>
    <SwaFooter/>
    </>
  );
};

export default Refund;
