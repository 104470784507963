import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Workshop.css';
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import backgroundImage from '../../img/education.jpg'; // Update the path

function Workshop() {
    const [activeTab, setActiveTab] = useState('previousWorkshops');
    const [isMobile, setIsMobile] = useState(() => JSON.parse(localStorage.getItem("isMobile") || "false"));

    useEffect(() => {
        const handleResize = () => {
            const mobileStatus = window.innerWidth < 768;
            setIsMobile(mobileStatus);
            localStorage.setItem("isMobile", JSON.stringify(mobileStatus));
        };
        window.addEventListener("resize", handleResize);
        handleResize(); // Initialize on mount

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const backgroundStyle = {
        width: '100%',
        height: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed', // This can be 'scroll' if you want it to scroll away with the page
        backgroundSize: 'cover', // Ensure that the background covers the entire element
        filter: 'blur(8px)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    };
    return (
        <>
            {isMobile ? <MobileNavbar /> : <SwaNavbar />}
            <div style={backgroundStyle}></div>

            <div style={{ position: 'relative', zIndex: 1 }}> {/* This ensures the content overlays the background */}

            <div className="workshop-container">
                <h1 className="title_download bold text-center mbc">Workshops</h1>
                <div className="tabs-container">
                    <div className={`tab ${activeTab === 'previousWorkshops' ? 'active' : ''}`}>
                        <input type="radio" id="tab1" name="tabGroup1" className="tab-input" onClick={() => setActiveTab('previousWorkshops')} />
                        <label htmlFor="tab1" className="tab-label">Previous Workshops</label>
                        <div className="tab-content">
                            {/* Content for Previous Workshops */}
                            Content goes here...
                        </div>
                    </div>
                    <div className={`tab ${activeTab === 'upcomingWorkshops' ? 'active' : ''}`}>
                        <input type="radio" id="tab2" name="tabGroup1" className="tab-input" onClick={() => setActiveTab('upcomingWorkshops')} />
                        <label htmlFor="tab2" className="tab-label">Upcoming Workshops</label>
                        <div className="tab-content">
                            {/* Content for Upcoming Workshops */}
                            More content goes here...
                        </div>
                    </div>
                </div>
            </div>
          
            <div class="mbc-container">

            <Link to="/education"><button className="nice-blue-button scroll-button">Back to Education</button></Link>
            </div>
            </div>
            <SwaFooter />
        </>
    );
}

export default Workshop;
