import React, { useState,useEffect } from 'react';
import './Workshop.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';
import backgroundImage from '../../img/education.jpg'; // Update the path

import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import { Card, Table, ListGroup  } from 'react-bootstrap';
import {  FaTimes,FaCheck, FaCreditCard, FaRegCalendarAlt } from 'react-icons/fa';
import SwaFooter from "../Screenwriters/SwaFooter";

function Scholar() {
    const [activeTab, setActiveTab] = useState('previousWorkshops');
    const [isMobile, setIsMobile] = useState(() => {
        const initialValue = localStorage.getItem("isMobile");
        return initialValue ? JSON.parse(initialValue) : false;
    });
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const backgroundStyle = {
        width: '100%',
        height: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed', // This can be 'scroll' if you want it to scroll away with the page
        backgroundSize: 'cover', // Ensure that the background covers the entire element
        filter: 'blur(8px)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    };
    return (
        <>
        {isMobile ? <MobileNavbar /> : <SwaNavbar />}
        <div style={backgroundStyle}></div>

        <div style={{ position: 'relative', zIndex: 1 }}> {/* This ensures the content overlays the background */}

        <div className="col-md-12">
                            <h1 className="title_download bold text-center mbc"> Scholarships </h1>
                        </div>
        <div className="tabs-container">
            <div className={`tab ${activeTab === 'previousWorkshops' ? 'active' : ''}`}>
                <input type="radio" id="tab1" name="tabGroup1" className="tab-input" onClick={() => handleTabClick('previousWorkshops')} />
                <label htmlFor="tab1" className="tab-label">Swadhyay</label>
                <div className="tab-content">
                    {/* Content for Previous Workshops tab */}
                </div>
            </div>
            <div className={`tab ${activeTab === 'upcomingWorkshops' ? 'active' : ''}`}>
                <input type="radio" id="tab2" name="tabGroup1" className="tab-input" onClick={() => handleTabClick('upcomingWorkshops')} />
                <label htmlFor="tab2" className="tab-label">Swabhimaan</label>
                <div className="tab-content">
                    {/* Content for Upcoming Workshops tab */}
                </div>
            </div>
            {/* Add more tabs as needed */}
        </div>

        <div class="mbc-container">
      <Link to="/education" ><button class="nice-blue-button scroll-button">Back to Education</button></Link>
      </div>
      </div>
        <SwaFooter/>

        </>
    );
}

export default Scholar;
