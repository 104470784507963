import React, { useState,useEffect } from "react";
import { Input, Card, Space, Dropdown, Menu } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { EnglishLang } from "../AboutSwa/Translation/English";
import { HindiLang } from "../AboutSwa/Translation/Hindi";
// import "./ScreenWriters.css";
import "./Swares.css";
import { FaInstagram, FaYoutube, FaTwitter, FaBloggerB } from 'react-icons/fa';
import TranslateIcon from "./Translate";
{/* <script src="http://translate.google.com/translate_a/element.js?cb=loadGoogleTranslate"></script> */}

// import { link } from "fs-extra";

const { Search } = Input;

function SwaNavbar() {



//   const loadGoogleTranslate = () => {
//     const existingScript = document.getElementById('googleTranslateScript');
//     if (!existingScript) {
//         const script = document.createElement('script');
//         script.id = 'googleTranslateScript';
//         script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
//         script.async = true;
//         document.body.appendChild(script);
//     }
// };


//Initialization function for Google Translate
// window.googleTranslateElementInit = () => {
//   if (window.google && window.google.translate) {
//       new window.google.translate.TranslateElement({
//           pageLanguage: 'en',
//           includedLanguages: 'en,hi' // Only include English and Hindi
//       }, 'google_translate_element');
//   }
// };

    const [selectedCard, setSelectedCard] = useState(null);
    const [visibleDropdown, setVisibleDropdown] = useState(null);
    const curLang = localStorage.getItem('curLang');
    const [visibleSubMenu, setVisibleSubMenu] = useState(false);
    const [translateDropdownVisible, setTranslateDropdownVisible] = useState(false); // State for the translate dropdown visibility
    const cardDetails = [
        {
            id: 1,
            // title: curLang == 'English' ?  EnglishLang.navbar_link_heading_1 : HindiLang.navbar_link_heading_1,
            title: "Become a Member",
            description:  EnglishLang.navbar_link_sub_head_1,
            imageUrl: "./Layer 88.png",
            menuItems: [
                { key: "1", title: "Apply Online", link: "https://swa.nexzendigital.com/register" },
                { key: "2", title: "Membership Rules",link: "/membershiprules" },
                { key: "3", title: "FAQ", link: "/faq" },
                // { key: "4", title: "History At SWA", link: "/history" },
            ],
        },
        {
            id: 2,
            title: "Register Your Work",
            description: "Submit Your Best",
            imageUrl: "./Layer 86.png",
            menuItems: [
                { key: "9", title: "My Account", link:"https://swa.nexzendigital.com/swa_account/login" },
                { key: "10", title: "FAQ", link: "/faq"  },
                // { key: "11", title: "Option 6" },
            ],
        },
        {
            id: 3,
            title: "Legal Help",
            description: "Advice For You",
            imageUrl: "./Layer 87.png",
            menuItems: [
                { key: "11", title: "MBC", link:"/mbc" },
                { key: "12", title: "DSC", link:"/dsc" },
                { key: "13", title: "Legal FAQs", link: "/faq"  },
                { key: "14", title: "Blogs", link: "/blog"  },
                { key: "15", title: "Book Appointment", link:"https://swa.nexzendigital.com/swa_account/login" },

            ],
        },
        {
            id: 4,
            title: "About Us",
            description: "Know Us",
            imageUrl: "./Layer 100.png",
            menuItems: [
                { key: "5", title: "Our Mission", link: "/mission" },
                { key: "6", title: "Our Constitution",link: "/mission#constitution-section" },
                { key: "7", title: "People of SWA", submenuKey: "submenu-3" },
                { key: "8", title: "History At SWA", link: "/history" },
            ],
        }

    ];
    const submenus = [
        {
            key: "submenu-3",
            title: "Submenu",
            items: [
                { key: "7-1", title: "Executive Committee", link: "/members" },
                { key: "7-2", title: "Sub Committee", link: "/subCommittee" },
                { key: "7-3", title: "Staff",link: "/executiveCommittee" },
            ],
        }

    ];

    const renderMenu = (menuItems) => (
      <Menu onClick={handleMenuClick}>
      {menuItems.map((menuItem) => {
          if (menuItem.submenuKey) {
              const submenu = submenus.find(submenu => submenu.key === menuItem.submenuKey);
              return (
                  <Menu.SubMenu key={menuItem.key} title={menuItem.title} className="submenu-item">
                      {submenu.items.map((subItem) => (
                          <Menu.Item key={subItem.key} className="submenu-item">
                              {subItem.link ? (
                                  subItem.link.startsWith("http") ? (
                                      <a href={subItem.link} target="_blank" rel="noopener noreferrer" className="a submenu-link">
                                          {subItem.title}
                                      </a>
                                  ) : (
                                      <Link to={subItem.link} className="a submenu-link">
                                          {subItem.title}
                                      </Link>
                                  )
                              ) : subItem.title}
                          </Menu.Item>
                      ))}
                  </Menu.SubMenu>
              );
          } else {
              return (
                  <Menu.Item key={menuItem.key} className="submenu-item">
                      {menuItem.link ? (
                          menuItem.link.startsWith("http") ? (
                              <a href={menuItem.link} target="_blank" rel="noopener noreferrer" className="a submenu-link">
                                  {menuItem.title}
                              </a>
                          ) : (
                              <Link to={menuItem.link} className="a submenu-link">
                                  {menuItem.title}
                              </Link>
                          )
                      ) : menuItem.title}
                  </Menu.Item>
              );
          }
      })}
      </Menu>
  );
  


    const handleCardClick = (cardNumber) => {
        setSelectedCard(cardNumber);
        setVisibleDropdown(cardNumber);
    };

    const handleMenuClick = (e) => {
        setVisibleSubMenu(e.key === "submenu");

    };
    const toggleTranslateDropdown = () => {
        setTranslateDropdownVisible(!translateDropdownVisible);
      };

      // Menu items for the translate dropdown
      const translateMenu = (
        
        <Menu>
          <Menu.Item key="english">English</Menu.Item>
          <Menu.Item key="hindi">Hindi</Menu.Item>
        </Menu>
      );


    //   useEffect(() => {
    //     loadGoogleTranslate();
    // }, []);

   

    return (
        <>
             <div className="blue__navbar">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="col">
            {/* Left side space */}
          </div>

          <div className="col">
            {/* Centered content */}
          </div>

          <div className="col d-flex justify-content-end align-items-center">


          {/* <div id="google_translate_element"></div> */}
         
                 {/* <Dropdown
                            overlay={<div id="google_translate_element" style={{ padding: 8 }}></div>}
                            visible={translateDropdownVisible}
                            onVisibleChange={setTranslateDropdownVisible}
                            trigger={['click']}
                        >
                            <div onClick={toggleTranslateDropdown} style={{ cursor: 'pointer' }}>
 <div className="col d-flex justify-content-end align-items-center">
                        <TranslateIcon />  
                    </div>                            
                    </div>
                        </Dropdown> */}
                        {/* <TranslateIcon/> */}
            <Search
              placeholder="Search"
              onSearch={(value) => console.log(value)}
              prefix={<SearchOutlined />}
              style={{ width: '293px', height: '35px', marginTop: '0.6rem', borderStartStartRadius: '50px' }}
            />
            <div className="social-icons" style={{ marginLeft: "1rem", marginRight: "1rem", display: 'flex', alignItems: 'center' }}>
    <a href="https://www.facebook.com/swaindiaorg" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <img src={require("../../img/fb-removebg-preview(1).png")} alt="Facebook" style={{ width: '40px', height: '40px' }} />
    </a>
    <a href="https://www.youtube.com/screenwritersassociation" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <img src={require("../../img/youtube-removebg-preview.png")} alt="YouTube" style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.instagram.com/swaindiaorg/" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <img src={require("../../img/Untitled-removebg-preview.png")} alt="Blog" style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fswaindiaorg" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <img src={require("../../img/icons8-twitter-50.png")} alt="Twitter" style={{ width: '30px', height: '30px' }} />
    </a>
</div>
          </div>
        </div>
      </div>

      <div className="sticky_navbar" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="logo" style={{ height: '6px', width: '3rem' }}>
                <Link to="/">
                  <div className="img-div">
                    <img className="nav-img" src="./logo.png" alt="Logo" />
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-8">
              <div className="d-flex bo-box" style={{flexWrap: "wrap"}}>
                {cardDetails.map((card) => (
                  <Dropdown
                    key={card.id}
                    overlay={renderMenu(card.menuItems)}
                    placement="bottomCenter"
                    visible={selectedCard === card.id}
                    onVisibleChange={(visible) => setSelectedCard(visible ? card.id : null)}
                  >
                    <Card
                      className={`${selectedCard === card.id ? 'selected-card' : ''} card-hover-effect`}
                      onClick={() => handleCardClick(card.id)}
                      style={{
                        height: '7.5rem',
                        borderRadius: 5,
                        border: '1px solid #d3a221',
                        marginRight: '1rem',
                        cursor: 'pointer',
                          ':hover': {
                            backgroundColor: 'blue !important', // Example hover color
                          }
                      }}
                    >
                      <Space align="start">
                        <img src={card.imageUrl} alt={`Layer ${card.id}`} />
                        <Space direction="vertical">
                          <Card.Meta
                            title={<div className="meta_title">{card.title}</div>}
                            description={<div className="meta_description">{card.description}</div>}
                          />
                        </Space>
                      </Space>
                    </Card>
                  </Dropdown>
                ))}
              </div>
            </div>

            <div className="col-md-2 my_account">
              <a href="https://swa.nexzendigital.com/swa_account/login" target="_blank" rel="noopener noreferrer">
                <img src="./myaccount.png" alt="My Account" />
              </a>
            </div>
          </div>
        </div>
      </div>
            {/* Grey navbar end */}
        </>
    );
}

export default SwaNavbar;
