import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SwaNavbar from "../../Screenwriters/SwaNavbar";
import MobileNavbar from "../../Screenwriters/MobileNavbar";
import SwaFooter from "../../Screenwriters/SwaFooter";
import "./ExcecutiveCommitee.css";

const Members = () => {
    const [executiveMembers, setExecutiveMembers] = useState([]);
    const [isMobile, setIsMobile] = useState(() => JSON.parse(localStorage.getItem("isMobile") || 'false'));
    const [error, setError] = useState('');

    useEffect(() => {
        const checkScreenWidth = () => {
            const isMobileScreen = window.innerWidth < 768;
            setIsMobile(isMobileScreen);
            localStorage.setItem("isMobile", JSON.stringify(isMobileScreen));
        };

        checkScreenWidth();
        window.addEventListener("resize", checkScreenWidth);

        return () => window.removeEventListener("resize", checkScreenWidth);
    }, []);

    useEffect(() => {
        const fetchExecutiveMembers = async () => {
            try {
                const response = await axios.get('https://swa.nexzendigital.com/api/get_committee');
                if (response.data && response.data.data && response.data.data.committee_list) {
                    // Find the "EXECUTIVE COMMITTEE" entry
                    const executiveCommittee = response.data.data.committee_list.find(committee => committee.name === "EXECUTIVE COMMITTEE");
                    if (executiveCommittee && executiveCommittee.members) {
                        setExecutiveMembers(executiveCommittee.members);
                    } else {
                        setError('No executive committee members found');
                    }
                } else {
                    setError('Invalid data structure from API');
                }
            } catch (error) {
                setError('Failed to fetch executive members');
                console.error('Error fetching executive members:', error);
            }
        };

        fetchExecutiveMembers();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            {isMobile ? <MobileNavbar /> : <SwaNavbar />}
            <div className="content-container ">
                <div className="container border-container margin-bt" style={{ marginTop: '3.5rem',marginBottom: '3.5rem' }}>
                    <section className="section-team">
                        <div className="container">
                            <div className="row justify-content-center text-center">
                                <div className="col-md-8 col-lg-6">
                                    <div className="header-section">
                                        <h1 className="title_ec bold text-center">Let's meet with our Official Executive Committee Members</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {executiveMembers.map((member, index) => (
                                    <div key={index} className="col-sm-6 col-lg-4 col-xl-3">
                                        <div className="single-person">
                                            <div className="person-image">
                                                <img src={member.image} alt={member.name} />
                                            </div>
                                            <div className="person-info">
                                                <h3 className="full-name">{member.name}</h3>
                                                <span className="speciality">{member.designation}</span>
                                                {/* If you need to show popup text, you can add it here */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <SwaFooter />
        </>
    );
};

export default Members;
