import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import MediationFormPDF  from "../../pdf/DSC Mediation Form Latest.pdf";
import './DSC.css'; // Your CSS file for styling
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import { Card, Table, ListGroup  } from 'react-bootstrap';
import {  FaTimes,FaCheck, FaCreditCard, FaRegCalendarAlt } from 'react-icons/fa';
import SwaFooter from "../Screenwriters/SwaFooter";

const DSC = () => {
  
    const [isMobile, setIsMobile] = useState(() => {
        const initialValue = localStorage.getItem("isMobile");
        return initialValue ? JSON.parse(initialValue) : false;
    });
    useEffect(() => {
      const checkScreenWidth = () => {
        setIsMobile(window.innerWidth < 768);
        localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 768));
      };
    
      checkScreenWidth();
      window.scrollTo(0, 0);
    
      window.addEventListener("resize", checkScreenWidth);
    
      return () => {
        window.removeEventListener("resize", checkScreenWidth);
      };
    }, []);
  const items = [
    {
      title: 'DSC Bye-Laws',
      link: '/byelaws', // You'll need to convert the PDF to an HTML page or component and route it here
    },
    {
      title: 'Mediation Form',
      link: MediationFormPDF  , // Link to your mediation form page or component
    },
    {
      title: 'Copyright Act',
      link: 'http://cms.swaindia.org/uploads/Copyright%20Amendment%20Act%202012%20(GoI).pdf',
    },
    {
      title: 'Copyright Rules',
      link: 'http://cms.swaindia.org/uploads/Copyright%20Rules%202013%20(GoI).pdf',
    },
    {
      title: 'SWA Bye-Laws',
      link: 'http://cms.swaindia.org/uploads/SWA%20Bye%20laws%20-2022.pdf',
    },
    {
      title: 'Ask Your DSC FAQ',
      link: '/faq', // Link to your FAQ page or section
    },
  ];

  return (
    <>
        {isMobile ? <MobileNavbar /> : <SwaNavbar />}

        <div className="dsc-page  margin-bt">
        <div className="col-md-12">
                            <h1 className="title_download bold text-center mbc"> DSC (Dispute Settlement Committee) </h1>
                        </div>
    <div className="dsc-page">
      
      {items.map((item, index) => (
                                <Link to={item.link} className="dsc-card-link" key={index} style={{ textDecoration: 'none' }}>

         <Card className="dsc-card2"
         hoverable
         style={{
           width: '300px',
           height: '173px', // Height is sqrt(3)/2 * width to maintain the hexagon aspect ratio
           backgroundColor: '#f0f2f5',
          
           border: '1px solid rgb(211, 162, 33)',
           marginBottom: '20px',
           boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
           transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
           clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
           display: 'flex',
           color: '#000',
           justifyContent: 'center',
           alignItems: 'center',
          
         }}
         onMouseOver={(e) => {
          const card = e.currentTarget;
          e.currentTarget.style.transform = 'scale(1.05)';
    e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
    e.currentTarget.style.cursor = 'pointer' // Change background color on hover
    e.currentTarget.style.backgroundColor = 'rgb(211, 162, 33)'; // Change background color on hover
    const textElements = card.querySelectorAll('.card-title'); // Use the correct selector for your text elements
    textElements.forEach(el => el.style.color = '#fff');

         }}
         onMouseOut={(e) => {
          const card = e.currentTarget;
          const textElements = card.querySelectorAll('.card-title'); // Use the correct selector for your text elements
          textElements.forEach(el => el.style.color = '#000');
      
           e.currentTarget.style.transform = 'scale(1)';
           e.currentTarget.style.backgroundColor = '#f0f2f5'; // Change background color on hover
                      e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
         }}
       >
        {/* <div key={index}  onClick={() => window.open(item.link, '_blank')}> */}
          <div className="card-content">
            <h2 className="card-title">{item.title}</h2>
          </div>
        {/* </div> */}
        </Card>
        </Link>
      ))}
      </div>
     
      </div>
      <div class="mbc-container">
      <Link to="/" ><button class="nice-blue-button scroll-button">Back to Home</button></Link>
      </div>
    <SwaFooter/>
    </>

  );
};

export default DSC;
