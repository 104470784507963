// ArticlePage.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

function ArchivePage() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`https://swa.nexzendigital.com/api/archive/${id}`);
        setArticle(response.data);
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    fetchArticle();
  }, [id]);

  if (!article) return <div>Loading...</div>;

  return (
    <div>
      {/* Link to go back to the main archive page */}
      <Link to="/archive">Back to Archive</Link> {/* Update the path as needed */}

      <h1>{article.art_main_heading}</h1>
      <p>Author: {article.author_name}</p>
      <p>Date: {article.art_date}</p>
      {/* More article details */}
    </div>
  );
}

export default ArchivePage;
