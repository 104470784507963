import React, { useState } from "react";
import { Link } from 'react-router-dom';

import "./ScreenWriters.css";
import { SlArrowLeft, SlArrowRight} from "react-icons/sl";
import * as FeatherIcons from 'react-icons/fi';
console.log(FeatherIcons, "FeatherIcons")

function SwaPhotoGallery() {
    const [currentImage, setCurrentImage] = useState(0);
    const images = ['./Layer 103.png', './F RT 1.jpg', './HOME PAGE UPDATES office.jpeg', './ScriptLab&Pitchfest_winners.jpg'];

    const handlePrevClick = () => {
        setCurrentImage((prevImage) => (prevImage === 0 ? 0 : prevImage - 1));
    };

    const handleNextClick = () => {
        setCurrentImage((prevImage) => (prevImage === images.length - 1 ? prevImage : prevImage + 1));
    };

    return (
        <>
            {/* Photo Gallery Start */}
            <div className="photo_gallery">
   
    <div className="box" > 
    <p >Photo Gallery
        {/* <p style={{textAlign:"center"}}>Photo Gallery</p> */}
        <span>
            <SlArrowLeft
                style={{
                    marginRight: '3rem',
                    cursor: 'pointer',
                    color: "#d3a221",
                    opacity: currentImage === 0 ? 0.3 : 1,
                }}
                onClick={handlePrevClick}
            />
            <SlArrowRight
                style={{
                    cursor: 'pointer',
                    color: "#d3a221",
                    opacity: currentImage === images.length - 1 ? 0.3 : 1,
                }}
                onClick={handleNextClick}
            />
        </span>
    </p>{/* 2px golden border added here */}
        <img src={images[currentImage]} alt="image" />
        <div class="mbc-container">
      <Link to="/" ><button class="nice-blue-button scroll-button">View more</button></Link>
      </div>
    </div>
</div>

            {/* Photo Gallery End */}
        </>
    );
}

export default SwaPhotoGallery;
