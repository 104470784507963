import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import './indexdata.css';

function IndexNavbar() {
    return (
        <div>
            <div className="maindiv">
                <div class="row">                    
                    <div className="for-work-nav">
                        <div className="index-text">
                        {/* <Link to="/">
                            <FaArrowLeft className="arrow-icon" />
                        </Link> */}
                         <Link to="/" style={{ color: "black", textDecoration: "none" }}>
              <i class="fa-solid fa-house"></i>
            </Link>
                            <h1>Index</h1>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div className="for-index-nav">
                        <h1>
                            <Link
                                to="/Workpage"
                                style={{ color: "black", textDecoration: "none" }}
                            >
                                Work
                            </Link>
                        </h1>
                        <h1>
                            <Link
                                to="/Aboutpage"
                                style={{ color: "black", textDecoration: "none" }}
                            >
                                About us
                            </Link>
                        </h1>
                        <h1>
                            <Link
                                to="/Contactpage"
                                style={{ color: "black", textDecoration: "none" }}
                            >
                                Contact us
                            </Link>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IndexNavbar;
