import React, { useState, useEffect ,useRef} from "react";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar"; // Importing CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faClock, faBuilding } from '@fortawesome/free-solid-svg-icons';

const ContactPage = () => {
  // State for form inputs, add more as needed
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [membershipNumber, setMembershipNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [comments, setComments] = useState('');
  const [isMember, setIsMember] = useState(true);
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 768);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 768));
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  // Add other state and handlers as needed

  return (
    <>
    {isMobile ? <MobileNavbar /> : <SwaNavbar />}
    <div className="border-container" style={{ display: 'flex', maxWidth: '1400px', margin: '20px auto', fontFamily: 'Sans-serif' }}>
      <div style={{ flex: 1, padding: '20px' }}>
        <h1 className="headings" style={{  paddingBottom: '5px' }}>Write to SWA</h1>
        <form>
          <input 
            type="text" 
            placeholder="Name*" 
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: '100%', padding: '10px', margin: '10px 0', boxSizing: 'border-box' }} 
          />
          <input 
            type="email" 
            placeholder="Email*" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '100%', padding: '10px', margin: '10px 0', boxSizing: 'border-box' }} 
          />
          <input 
            type="text" 
            placeholder="SWA Membership Number*" 
            value={membershipNumber}
            onChange={(e) => setMembershipNumber(e.target.value)}
            style={{ width: '100%', padding: '10px', margin: '10px 0', boxSizing: 'border-box' }} 
          />
          <label>
            <input 
              type="checkbox" 
              checked={!isMember}
              onChange={(e) => setIsMember(!e.target.checked)}
              style={{ marginRight: '5px' }}
            />
            Not A Member
          </label>
          <select 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            style={{ width: '100%', padding: '10px', margin: '10px 0', boxSizing: 'border-box' }}
          >
            <option value="">Select a subject</option>
            <option value="query">Query</option>
            <option value="feedback">Feedback</option>
                    <option value="other">Other</option>
                    <option value="legal_consultation">Legal Officer - Consultation</option>
                    <option value="legal_appointment">Legal Officer - Appointment</option>
                    <option value="dsc">To the DSC</option>
                    <option value="tech_support_signin">TECHNICAL SUPPORT - NOT ABLE TO SIGN IN/SIGN UP</option>
                    <option value="tech_support_registration">TECHNICAL SUPPORT - ONLINE SCRIPT REGISTRATION</option>
          </select>
          <textarea 
            placeholder="Comments*" 
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            style={{ width: '100%', padding: '10px', margin: '10px 0', height: '100px', boxSizing: 'border-box' }} 
          />
          <button 
            type="submit" 
            style={{ width: '100%', padding: '10px', backgroundColor: 'black', color: 'white', border: 'none', cursor: 'pointer' }}
          >
            Submit
          </button>
        </form>
      </div>
      <div style={{ flex: 1, padding: '20px 50px' }}>
        <h1 className="headings" style={{  paddingBottom: '5px' }}>Address & Contact</h1>
        <p><FontAwesomeIcon icon={faBuilding} />   201 - 204, Richa Building, Plot No. B - 29,<br />
                    Off New Link Road, Opposite Citi Mall,<br />
                    Andheri (West) Mumbai,<br />
                    Maharashtra - 400 053,<br />
                    India<br />
                    </p><br/>
                    <p><FontAwesomeIcon icon={faPhone} />  +91 22 2673 3027 / +91 22 2673 3108 / +91 22 6692 2899<br /></p><br/>
                   <p><FontAwesomeIcon icon={faEnvelope} /> contact@swaindia.org<br /></p><br/>
                  <p><FontAwesomeIcon icon={faClock} /><strong>  Office Time*:</strong>  Monday - Saturday | 11.00 am - 7.00 pm<br /></p><br/>
                   <p><FontAwesomeIcon icon={faClock} /><strong>  Membership (New/Renewal):</strong>  Monday - Saturday | 11.30 am - 6.00 pm<br /></p><br/>
                   <p><FontAwesomeIcon icon={faClock} /><strong>  Offline Script Registration Days:</strong>  Tuesday and Friday | 2.00 pm - 5.30 pm<br /></p><br/>
                    <p>*Closed on Sunday and Bank Holidays.</p><br/>
      </div>
    </div>
    <SwaFooter/>
    </>
  );
}

export default ContactPage;
