import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ScreenWriters.css";

function SwaOptionName() {
    const [hovered, setHovered] = useState(Array(7).fill(false));

    const handleMouseEnter = (index) => {
        const newHoveredState = Array(7).fill(false);
        newHoveredState[index] = true;
        setHovered(newHoveredState);
    };

    const handleMouseLeave = () => {
        setHovered(Array(7).fill(false));
    };

    const embossedStyle = {
        boxShadow: "inset 0 4px 6px rgba(255, 255, 255, 0.6), inset 0 -4px 6px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        padding: '10px 20px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        width: '100%', // Adjust width as necessary
        justifyContent: 'start',
        margin: '5px 0', // Spacing between buttons
        cursor: 'pointer'
    };

    // Option details including the path and label
    const options = [
        { path: "/mbc", label: "MBC (Minimum Basic Contract)" },
        { path: "/dsc", label: "DSC (Dispute Settlement Committee)" },
        { path: "/downloads", label: "Downloads" },
        { path: "/welfare", label: "Welfare" },
        { path: "/archive", label: "Archive" },
        { path: "/faq", label: "FAQ" }
    ];

    return (
        <>
            <div className="welcome__section" style={{ color: 'black' }}>
                <h1>Member Support</h1>
                <hr style={{ width: '100%' }} />
            </div>

            <div className="blue__box">
                {options.map((option, index) => (
                    <Link to={option.path} key={index} style={{ textDecoration: 'none' }} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                        <div className="box__line" style={embossedStyle}>
                            <img src={hovered[index] ? "./angle-circle-right.png" : "./right-chevron.png"} alt="Icon" />
                            <span>{option.label}</span>
                        </div>
                    </Link>
                ))}
            </div>

            <Link to="/awards" className="a  black__box">
                <img src="./SWA-AWARD-LOGO-03.png" alt="SWA AWARDS" />
                <div className="yellow__box">
                <span>SWA AWARDS</span>
                <p className="swa-award-h3">Recognising and honouring outstanding contributions.</p>
                </div>
            </Link>
        </>
    );
}

export default SwaOptionName;
