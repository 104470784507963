import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./ScreenWriters.css";
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import '../ByeLaws/ByeLaws.css';
import backgroundImage from '../../img/events.jpg'; // Update the path

function Events() {
  
    const [index, setIndex] = useState(0);
    const [homeSlider, setHomeSlider] = useState([]);
    const [isMobile, setIsMobile] = useState(() => {
        const initialValue = localStorage.getItem("isMobile");
        return initialValue ? JSON.parse(initialValue) : false;
      });
      
      useEffect(() => {
        const checkScreenWidth = () => {
          setIsMobile(window.innerWidth < 768);
          localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 768));
        };
    
        checkScreenWidth();
        window.scrollTo(0, 0);

        window.addEventListener("resize", checkScreenWidth);
    
        return () => {
          window.removeEventListener("resize", checkScreenWidth);
        };
      }, []);

     

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    // API call for get-home-page-slider
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://swa.nexzendigital.com/api/get-home-page-slider');
                console.log('API Response:', response.data);
                if (response.data && response.data.status && response.data.data && response.data.data.home_slider) {
                    setHomeSlider(response.data.data.home_slider);
                    console.log(homeSlider, "homeSlider")
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [homeSlider]);

    const backgroundStyle = {
        width: '100%',
        height: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed', // This can be 'scroll' if you want it to scroll away with the page
        backgroundSize: 'cover', // Ensure that the background covers the entire element
        filter: 'blur(18px)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    };
    return (
        <>
            {isMobile ? <MobileNavbar /> : <SwaNavbar />}
            <div style={backgroundStyle}></div>
        <div style={{ position: 'relative', zIndex: 1 }}> 
            <div className="appointment-container border-container margin-bt">
            <h1 className='headings' style={{color: 'white'}}>Events</h1>
            <p className="mission-statement">
            The Events Subcommittee of the Screenwriters Association (SWA) organises roundtables, panel discussions, one-on-one Vartalaaps, honorary events and several other community mixers on a regular basis.<br/>By engaging and bringing together new entrants and working professionals, the Subcommittee hopes to inspire, inform, and encourage screenwriters and lyricists to work on their craft, and also give them opportunities to meet in person, ultimately building solidarity and camaraderie among the community. </p>
            <h1 className='headings' style={{color: 'white'}}>Upcoming Events</h1>

            <div className="slider__box">
                <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>
                    {/* Map API get-home-page-slider data */}
                    {homeSlider.map((slide, index) => (
                        <Carousel.Item key={index}>
                            <img src={slide.image} alt={`Slide ${index + 1}`} />
                            <a href={slide.link} target="_blank" rel="noopener noreferrer">
                                {slide.title && <div className="image-title">{slide.title}</div>}
                            </a>
                        </Carousel.Item>
                    ))}
                </Carousel>
                
            </div>
            </div>
            </div>
            <div class="mbc-container">
      <Link to="/" ><button class="nice-blue-button top-space scroll-button">Past Events</button></Link>
      </div>
            <SwaFooter/>

        </>
    );
}

export default Events;
