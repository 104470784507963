import React, { useState, useEffect } from "react";
import "./ScreenWriters.css";
import "./Contactform.css";
import "./Contacttitle.css";
import "./Work.css";
import "./indexdata.css";
import SwaNavbar from "./SwaNavbar";
import SwaOptionName from "./SwaOptionName";
import SwaPhotoGallery from "./SwaPhotoGallery";
import SwaVideoSection from "./SwaVideos";
import SwaCarousel from "./SwaHomeCarousel";
import SwaCards from "./SwaCards";
import SwaFooter from "./SwaFooter";
import MobileNavbar from "./MobileNavbar";

function ScreenWriters() {
    const [isMobile, setIsMobile] = useState(() => {
        const initialValue = localStorage.getItem("isMobile");
        return initialValue ? JSON.parse(initialValue) : false;
    });

    useEffect(() => {
        // Check screen width on mount and resize
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth < 768); // Set breakpoint according to your design
            localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 768));
        };

        checkScreenWidth(); // Initial check

        window.addEventListener("resize", checkScreenWidth); // Add event listener for resize

        return () => {
            window.removeEventListener("resize", checkScreenWidth); // Remove event listener on component unmount
        };
    }, []);

    return (
        <>
            {isMobile ? <MobileNavbar /> : <SwaNavbar />}

            {/* Content start */}
            <div className="content-container">
                <div className="container">
                    <div className="row">
                        {/* Left content */}
                        <div className="col-md-4 col-sm-12">
                            {/* Blue-black box Component */}
                            <SwaOptionName />

                            <div className="gallery-video-row">
        <div > {/* Take up half the container width */}
            <SwaPhotoGallery />
        </div>
        
        <div > {/* Take up half the container width */}
            <SwaVideoSection />
        </div>
    </div>
                        </div>

                        {/* Right content */}
                        <div className="col-md-8 col-sm-12">
                            {/* Carousel Component */}
                            <SwaCarousel />
<div style={{marginBottom:"100px"}}> </div>
                            {/* Cards Component */}
                            <SwaCards />
                            {/* Add more content as needed */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Content end */}

            {/* Footer Component */}
            <SwaFooter />
        </>
    );
}

export default ScreenWriters;
