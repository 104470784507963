// import React from "react";
// import Table from 'react-bootstrap/Table';
// import 'bootstrap/dist/css/bootstrap.min.css';
// function Indexdata() {
//     return (
//         <>
//             <div>
//                 <Table responsive="sm">
//                     <thead>
//                         <tr>
//                             <th>YEAR</th>
//                             <th>PROJECTS</th>
//                             <th>LOCATIONS</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>2023</td>
//                             <td>Table cell</td>
//                             <td>Table cell</td>
//                         </tr>
//                         <tr>
//                             <td>2022</td>
//                             <td>Table cell</td>
//                             <td>Table cell</td>
//                         </tr>
//                         <tr>
//                             <td>2022</td>
//                             <td>Table cell</td>
//                             <td>Table cell</td>
//                         </tr>
//                         <tr>
//                             <td>2022</td>
//                             <td>Table cell</td>
//                             <td>Table cell</td>
//                         </tr>
//                         <tr>
//                             <td>2023</td>
//                             <td>Table cell</td>
//                             <td>Table cell</td>
//                         </tr>
//                         <tr>
//                             <td>2021</td>
//                             <td>Table cell</td>
//                             <td>Table cell</td>
//                         </tr>
//                         <tr>
//                             <td>2022</td>
//                             <td>Table cell</td>
//                             <td>Table cell</td>
//                         </tr>
//                     </tbody>
//                 </Table>

//             </div>
//         </>
//     );
// }
// export default Indexdata;
import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import './indexdata.css';
import indexdata from '../Database/db.json'
function Indexdata() {
  return (
    
    <div className="table-container">
      <Table responsive="sm" className="table" >
        <thead className="table-header">
          <tr>        
            <th >CLIENT</th>
            <th>LOCATIONS</th>
            <th >TYPE</th>
            <th>YEAR</th>
            <th>PROJECTS</th>
          </tr>
        </thead>
        <tbody>
          {indexdata.map((row, index) => (
            <tr key={index} className="table-data">           
              <td >{row.projects}</td>
              <td >{row.client}</td>
              <td >{row.locations}</td>
              <td >{row.type}</td>
              <td >{row.year}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
export default Indexdata;
