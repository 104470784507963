import React, { useState, useEffect } from "react";
import axios from "axios";

function FaqCategory({ setSelectedCategory }) {
    const [categories, setCategories] = useState([]);
    const [hovered, setHovered] = useState(Array(categories.length).fill(false)); // Initialize an array with false values
    const [selected, setSelected] = useState(null); // Initialize selected category state

    // handleMouseEnter function
    const handleMouseEnter = (index) => {
        const newHoveredState = Array(categories.length).fill(false); // Create a new array with all false values
        newHoveredState[index] = true; // Set the hover state for the current index to true
        setHovered(newHoveredState); // Update the hovered state
    };

    // handleMouseLeave function
    const handleMouseLeave = () => {
        setHovered(Array(categories.length).fill(false)); // Reset all hover states to false when mouse leaves
    };

    // handleClick function
    const handleClick = (category) => {
        setSelectedCategory(category); // Set the selected category
        setSelected(category); // Update the selected state
    };

    // API call for FAQs
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://swa.nexzendigital.com/api/faqs');
                console.log('Categories:', response.data);
                setCategories(response.data.data.faqs.map(category => category.title));
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    // handleCategorySelect function
    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    return (
        <>
            <div className="faq__category__box">
                {/* Map FAQs API data */}
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className={`faq__category__option ${selected === category ? 'selected' : ''}`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleClick(category)}
                    >
                        {hovered[index] || selected === category ? (
                            <img src="./angle-circle-right.png" alt="Angle Circle Right" />
                        ) : (
                            <img src="./right-chevron.png" alt="Right Chevron" />
                        )}
                        <span style={{ color: hovered[index] || selected === category ? '#ffffff' : '#000000' }}>
                            {category}
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
}

export default FaqCategory;
