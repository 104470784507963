import React, { useState, useEffect } from "react";
import "./ScreenWriters.css";
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { Link } from 'react-router-dom';

function SwaCarousel() {
    const [index, setIndex] = useState(0);
    const [homeSlider, setHomeSlider] = useState([]);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    // API call for get-home-page-slider
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://swa.nexzendigital.com/api/get-home-page-slider');
                console.log('API Response:', response.data);
                if (response.data && response.data.status && response.data.data && response.data.data.home_slider) {
                    setHomeSlider(response.data.data.home_slider);
                    console.log(homeSlider, "homeSlider")
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>

<marquee width="100%" direction="left" height="50px" style={{paddingTop:'2%', color:"black"}}>
1. SWA members can now renew and upgrade their membership online.

2. Office hours for offline registration:

Script - Tue & Fri (2:00pm to 5:30 pm)
Membership - Mon to Sat (11:00 am to 6:00 pm)

3. SWA recommends reading of the DOs & DON'Ts FOR WRITERS. <Link to="/dodont">Visit  </Link>
4. ⁠Monthly holidays. (To be updated each month)</marquee>
            <div className="slider__box">
                <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>
                    {/* Map API get-home-page-slider data */}
                    {homeSlider.map((slide, index) => (
                        <Carousel.Item key={index}>
                            <img src={slide.image} alt={`Slide ${index + 1}`} />
                            <a href={slide.link} target="_blank" rel="noopener noreferrer">
                                {slide.title && <div className="image-title">{slide.title}</div>}
                            </a>
                        </Carousel.Item>
                    ))}
                </Carousel>

            </div>
        </>
    );
}

export default SwaCarousel;
