import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar";
// Styled components
const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', serif;
    
    line-height: 1.75;
  }
`;

const CharterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  max-width: 1400px;
  border: 5px solid rgb(211, 162, 33);
`;

const CharterTitle = styled.h1`
//   
  font-size: 2.8rem;
  margin-bottom: 1rem;
  text-align: center;
  font-family: 'Montserrat', Times, serif;
`;

const CharterText = styled.p`
//   color: #34495e;
  font-size: 1.9rem;
  max-width: 1300px;
  text-align: left;
  margin-bottom: 20px;
  &:first-of-type {
    font-style: italic;
  }
`;

const RightsList = styled.ul`
  list-style: none;
  max-width: 1300px;
`;

const RightItem = styled.li`
  color: #2c3e50;
  font-size: 1.9rem;
  margin-bottom: 10px;
  &:before {
    content: '✒️';
    margin-right: 10px;
    color: #3498db;
  }
`;

const GoalSection = styled.section`
  margin-top: 30px;
`;



const WritersCharter = () => {
    const [isMobile, setIsMobile] = useState(() => {
        const initialValue = localStorage.getItem("isMobile");
        return initialValue ? JSON.parse(initialValue) : false;
      });
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      useEffect(() => {
        const checkScreenWidth = () => {
          setIsMobile(window.innerWidth < 768);
          localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 768));
        };
    
        checkScreenWidth();
    
        window.addEventListener("resize", checkScreenWidth);
    
        return () => {
          window.removeEventListener("resize", checkScreenWidth);
        };
      }, []);
  return (
    <>
    {isMobile ? <MobileNavbar /> : <SwaNavbar />}
    <CharterContainer>
      <CharterTitle className="headings">The Writers Charter</CharterTitle>
      <CharterText>
        The writer is a primary creator of works of imagination, entertainment, and enlightenment; a significant initiator of cultural, social, and economic processes. These are of basic importance in all societies.
      </CharterText>
      <CharterText>
        To fulfil effectively these social responsibilities, the writer must have the following rights:
      </CharterText>
      <RightsList>
        <RightItem>The right to be acknowledged, legally, morally, and contractually, as the author of one's work.</RightItem>
        <RightItem>The right to complete freedom of expression and communication, and freedom from any form of censorship.</RightItem>
        <RightItem>The right to maintain the integrity of a work and to protect it from any distortion or misuse.</RightItem>
        <RightItem>The right to fair payment for all uses of a work.</RightItem>
        <RightItem>The right to have the work published or produced solely on the basis of its merit, without regard to any form of invidious discrimination which shall include but not be limited to age, color, gender, marital status, national origin, physical or emotional instability, race, religion, sexual orientation, social or political affiliation or belief of the writer.</RightItem>
        <RightItem>The right to form unions or guilds entitled to bargain and act collectively for their members.</RightItem>
      </RightsList>
      <GoalSection>
        <CharterText>
          Believing that these rights are fundamental to the dignity, integrity, and well-being of writers, it is the policy of the International Affiliation of Writers Guilds of which Screenwriters Association, Mumbai, is a full member, to work for their attainment everywhere, and as part of this effort to take all necessary steps to achieve the following goals:
        </CharterText>
        <RightsList>
          <RightItem>To work for copyright laws that are based on the concept that the creator of any work is its first owner, with inalienable legal and moral rights in that work that protect its use and integrity.</RightItem>
          <RightItem>To ensure continuing participation of the writer in the preparation and production of a work for film, radio, television or the stage. This includes the casting, the selection of a director, and the right to be present when the work is being produced or presented.</RightItem>
          <RightItem>To fight all attempts by others to claim authorship or ownership in a work, including moves by producers, directors and corporate entities to be accorded ‘possessory’ credits.</RightItem>
          <RightItem>To encourage and maintain the distinct cultural identities of each country.</RightItem>
          <RightItem>To oppose all attempts by governments, corporations, special interest groups, and others, to impose on creators censorship in any form.</RightItem>
          <RightItem>To seek means to facilitate the free movement of writers in and between all nations.</RightItem>
          <RightItem>To establish the conditions that will free the writer's imagination and fulfill his or her capacity for creative expression.</RightItem>
        </RightsList>
      </GoalSection>
    </CharterContainer>
    <SwaFooter/>
    </>
  );
};

export default WritersCharter;
