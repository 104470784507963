import React, { useEffect, useState } from "react";
import "./ScreenWriters.css";
import axios from 'axios';

function SwaVideoSection() {
    const [latestVideos, setLatestVideos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://swa.nexzendigital.com/api/latest-videos');
                if (response.data && response.data.status && response.data.data && response.data.data.latest_videos) {
                    setLatestVideos(response.data.data.latest_videos);
                }
            } catch (error) {
                console.error('Error fetching data Video:', error);
            }
        };

        fetchData();
    }, []);

    // Function to extract video ID from YouTube URL
    const extractVideoID = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11) ? match[2] : null;
    };

    return (
        <>
            <div className="video_section">
                <div className="video-grid">
                    {latestVideos.map((video, index) => {
                        const videoId = extractVideoID(video.video_link);
                        const embedUrl = `https://www.youtube.com/embed/${videoId}`;

                        return (
                            <div key={index} className="video-box">
                                                <p style={{textAlign:"center"}}>Watch our Latest Videos</p>

                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={embedUrl}
                                    title={`YouTube video player ${index}`}
                                    frameBorder="0"
                                    style={{ borderRadius: '5px' ,  padding: "20px" , height:"186px" }}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default SwaVideoSection;
