import React, { useState, useEffect } from "react";
import SwaNavbar from "../../Screenwriters/SwaNavbar";
import MobileNavbar from "../../Screenwriters/MobileNavbar";
import SwaFooter from "../../Screenwriters/SwaFooter";
import "./ExcecutiveCommitee.css";

function ExecutiveCommittee() {
    const [isMobile, setIsMobile] = useState(() => {
        const initialValue = localStorage.getItem("isMobile");
        return initialValue ? JSON.parse(initialValue) : false;
    });
    useEffect(() => {
        // Check screen width on mount and resize
        const checkScreenWidth = () => {
            setIsMobile(window.innerWidth < 768); // Set breakpoint according to your design
            localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 768));
        };

        checkScreenWidth(); // Initial check

        window.addEventListener("resize", checkScreenWidth); // Add event listener for resize

        return () => {
            window.removeEventListener("resize", checkScreenWidth); // Remove event listener on component unmount
        };
    }, []);

    // State to hold staff members data
    const [staffMembers, setStaffMembers] = useState([]);

    useEffect(() => {
        // Mock data for demonstration
        const mockData = [
            { id: 1, name: "Pramila Kadam", image: "../female.png", specialty: "Office Manager" },
            { id: 2, name: "Sanchit Dahake", image: "../male.png", specialty: "Event Manager" },
            { id: 3, name: "Diwakar Abhishek", image: "../male.png", specialty: "Legal Officer" },
            { id: 4, name: "Sumant Prajapati", image: "../male.png", specialty: "Assistant Executive Secretary" },
            { id: 5, name: "Dhiraj Jain", image: "../male.png", specialty: "Senior Accounts Executive" },
            { id: 6, name: "Aman Rajabali", image: "../male.png", specialty: "HR Consultant" },
            { id: 7, name: "Pravin Mandavkar", image: "../male.png", specialty: "Junior Assistant" },
            { id: 8, name: "Yashwant Shelar", image: "../male.png", specialty: "Junior Assistant" },
            { id: 9, name: "Amar Jadhav", image: "../male.png", specialty: "Office Boy" },
            { id: 10, name: "Santosh Patil", image: "../male.png", specialty: "Office Boy" },
        ];
        // Set mock data into state
        setStaffMembers(mockData);
    }, []);

    return (
        <>
            {/* Navbar Component */}
            {isMobile ? <MobileNavbar /> : <SwaNavbar />}

            {/* content start */}
            <div className="content-container ">
                {/* col-md-12 screen image */}
                {/* <div className="col-md-12">
                    <img src="./bg.jpg" alt="Screen" style={{ width: "100%", height: "auto" }} />
                </div> */}

                <div className="container border-container margin-bt" style={{ marginTop: '3.5rem' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <section className="section-team">
                                <div className="container">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-8 col-lg-6">
                                            <div className="header-section">
                                                <h1 className="title_ec bold text-center">Let's meet with our Official Staff Members</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* Map through staff members and render cards */}
                                        {staffMembers.map(member => (
                                            <div key={member.id} className="col-sm-6 col-lg-4 col-xl-3">
                                                <div className="single-person">
                                                    <div className="person-image">
                                                        <img src={member.image} alt={member.name} />
                                                    </div>
                                                    <div className="person-info">
                                                        <h3 className="full-name">{member.name}</h3>
                                                        <span className="speciality">{member.specialty}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                {/* footer component */}
                <SwaFooter />
            </div>
            {/* content end */}
        </>
    );
}

export default ExecutiveCommittee;
