import React, { useState } from "react";
import { Menu, Input, Button } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import { SearchOutlined, MenuOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./ScreenWriters.css";
const { Search } = Input;
import Translate from "./Translate";
import MenuItem from "antd/es/menu/MenuItem";
function MobileNavbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [visibleSubMenu, setVisibleSubMenu] = useState(false);
    const [openKeys, setOpenKeys] = useState([]);
    const [openSubMenuKeys, setOpenSubMenuKeys] = useState([]);

    const cardDetails = [
        {
            id: 1,
            // title: curLang == 'English' ?  EnglishLang.navbar_link_heading_1 : HindiLang.navbar_link_heading_1,
            title: "Become a Member",
            description:  "Join Us Now",
            imageUrl: "./Layer 88.png",
            menuItems: [
                { key: "1", title: "Apply Online", link: "https://swa.nexzendigital.com/register" },
                { key: "2", title: "Membership Rules",link: "/membershiprules" },
                { key: "3", title: "FAQ", link: "/faq" },
                // { key: "4", title: "History At SWA", link: "/history" },
            ],
        },
        {
            id: 2,
            title: "Register Your Work",
            description: "Submit Your Best",
            imageUrl: "./Layer 86.png",
            menuItems: [
                { key: "9", title: "My Account", link:"https://swa.nexzendigital.com/swa_account/login" },
                { key: "10", title: "FAQ", link: "/faq"  },
                // { key: "11", title: "Option 6" },
            ],
        },
        {
            id: 3,
            title: "Legal Help",
            description: "Advice For You",
            imageUrl: "./Layer 87.png",
            menuItems: [
                { key: "11", title: "MBC", link:"/mbc" },
                { key: "12", title: "DSC", link:"/dsc" },
                { key: "13", title: "Legal FAQs", link: "/faq"  },
                { key: "14", title: "Blogs", link: "/blog"  },
                { key: "15", title: "Book Appointment", link:"https://swa.nexzendigital.com/swa_account/login" },

            ],
        },
        {
            id: 4,
            title: "About Us",
            description: "Know Us",
            imageUrl: "./Layer 100.png",
            menuItems: [
                { key: "5", title: "Our Mission", link: "/mission" },
                { key: "6", title: "Our Constitution",link: "/mission#constitution-section" },
                { key: "7", title: "People of SWA", submenuKey: "17" },
                { key: "8", title: "History At SWA", link: "/history" },
            ],
        }

    ];
    const submenus = [
        {
            key: "17",
            title: "Submenu",
            items: [
                { key: "16", title: "Executive Committee", link: "/members" },
                { key: "17", title: "Sub Committee" },
                { key: "18", title: "Staff",link: "/executiveCommittee" },
            ],
        }

    ];

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleSubMenu = (key) => {
        const index = openKeys.indexOf(key);
        const updatedKeys = [...openKeys];
        if (index === -1) {
            updatedKeys.push(key);
        } else {
            updatedKeys.splice(index, 1);
        }
        setOpenKeys(updatedKeys);
    };

    const onOpenChange = (keys) => {
        setOpenKeys(keys);
    };

    const renderSubMenuItems = (menuItems) =>
    menuItems.map((item) =>
      item.submenuKey ? (
        <SubMenu key={item.key} title={item.title} onTitleClick={() => toggleSubMenu(item.key)}>
          {submenus.find((submenu) => submenu.key === item.submenuKey).items.map((subItem) => (
            <Menu.Item key={subItem.key} className="submenu-item">
              {subItem.link ? (
                <Link to={subItem.link} className="submenu-link">{subItem.title}</Link>
              ) : (
                subItem.title
              )}
            </Menu.Item>
          ))}
        </SubMenu>
      ) : (
        <Menu.Item key={item.key} className="submenu-item">
          {item.link ? <a href={item.link} className="submenu-link">{item.title}</a> : item.title}
        </Menu.Item>
      )
    );
  
    return (
        <div className="mobile-navbar">
            <div className="logo_mobile">
                <Link to="/">
                    <div className="img-div">
                        <img className="nav-img" src="./logo.png" alt="Logo" />
                    </div>
                </Link>
            </div>
            <Button type="text" onClick={toggleMenu} className="toggle-button">
                <MenuOutlined />
            </Button>
            {isMenuOpen && (
                <div className="mobile-menu">
                    <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
                    {cardDetails.map((card) => (
  <SubMenu
    key={card.id}
    title={
      <div className="card-hover-effect">
        <img style={{ width: "2.5rem", height: "2rem", marginRight: "0.5rem" }} src={card.imageUrl} alt={card.title} />
        <div>
          <div className="meta_title">{card.title}</div>
          <div className="meta_description">{card.description}</div>
        </div>
      </div>
    }
  >
    {renderSubMenuItems(card.menuItems)}
  </SubMenu>
))}

{/* <Menu.Item><Translate/></Menu.Item> */}
                        <Menu.Item key="search">
                           
                            <Search placeholder="Search" onSearch={(value) => console.log(value)} prefix={<SearchOutlined />} />
                        </Menu.Item>
                    </Menu>
                    
<div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: "1rem 0"
}}>
    <a href="https://www.facebook.com/swaindiaorg" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <img src={require("../../img/fb-removebg-preview(1).png")} alt="Facebook" style={{ width: '40px', height: '40px' }} />
    </a>
    <a href="https://www.youtube.com/screenwritersassociation" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <img src={require("../../img/youtube-removebg-preview.png")} alt="YouTube" style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://www.instagram.com/swaindiaorg/" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <img src={require("../../img/Untitled-removebg-preview.png")} alt="Blog" style={{ width: '30px', height: '30px' }} />
    </a>
    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fswaindiaorg" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
        <img src={require("../../img/icons8-twitter-50-removebg-preview.png")} alt="Twitter" style={{ width: '30px', height: '30px' }} />
    </a>
</div>

<div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: "1rem 0"
}}>
    <a href="https://swa.nexzendigital.com/swa_account/login" target="_blank" rel="noopener noreferrer">
        <img  src="./myaccount.png" alt="My Account" style={{ width: 'auto', height: 'auto' }} />
    </a>
    {/* <div id="google_translate_element"></div> */}

</div>
<div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: "1rem 0",
    // backgroundColor: 'black'
}}>
    {/* <Translate/> */}

    {/* <div id="google_translate_element"></div> */}

</div>

                </div>
            )}
        </div>
    );
}

export default MobileNavbar;
